import { Button as ButtonMui, Paper } from '@common/components'
import { styled } from '@common/utils/styles'

export const FilterButton = styled(ButtonMui, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  borderRadius: '5px',
  backgroundColor: selected ? theme.palette.neutral.main : 'inherit',
  color: selected ? theme.palette.neutral.contrastText : 'inherit',
  '&:hover': {
    backgroundColor: selected ? theme.palette.neutral.main : 'inherit',
    color: selected ? theme.palette.neutral.contrastText : 'inherit',
  },
}))

interface UserCardProps {
  subscriptionType?: 'pro' | 'basic' | string | null
}

export const UserCard = styled(Paper, {
  shouldForwardProp: (prop) => !['subscriptionType'].includes(prop as string),
})<UserCardProps>(({ subscriptionType, theme }) => ({
  backgroundColor: subscriptionType
    ? theme.palette.mode === 'dark'
      ? {
          pro: 'rgba(84, 79, 58, 0.4)',
          basic: 'rgba(30, 73, 118, 0.4)  ',
        }[subscriptionType]
      : {
          pro: '#f5f2d3',
          basic: '#E3F2FD',
        }[subscriptionType]
    : 'transparent',
  padding: '4px 8px',
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
  overflow: 'clip',
  ':after': {
    display: ['pro', 'basic'].includes(subscriptionType ?? '') ? 'flex' : 'none',
    content: `"${subscriptionType}"`,
    textTransform: 'uppercase',
    color: 'whitesmoke',
    fontSize: '10px',
    position: 'absolute',
    top: '5px',
    left: '-15px',
    background: '#8C80DB',
    width: '60px',
    justifyContent: 'center',
    transform: 'rotate(-40deg)',
  },
}))
