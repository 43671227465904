import { inputBaseClasses } from '@common/components'
import { TextField } from '@common/components'
import { alpha, styled } from '@common/utils/styles'

export const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .MuiInputBase-root`]: {
    borderRadius: '11px',
    backgroundColor: alpha('#A6ADB5', 0.15),
    boxShadow: 'none',
  },
  [`& .MuiInputBase-input`]: {
    borderRadius: '11px',
    boxShadow: 'none',
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px transparent inset !important',
      '-webkit-text-fill-color': `${theme.palette.text.primary} !important`,
      boxShadow: 'none',
      transition: 'background-color 5000s ease-in-out 0s',
    },
  },
}))
