import { Container, Skeleton, Grid } from '@common/components'
import { CenterContainer } from '@common/containers'

const InventoryPlaceholder = () => {
  return (
    <CenterContainer>
      <Skeleton
        variant='rectangular'
        width={'90%'}
        sx={{ height: '70vh', maxWidth: '450px', borderRadius: '10px' }}
      />
    </CenterContainer>
  )
}

export default InventoryPlaceholder
