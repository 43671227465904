import { Box, Button, Link, Modal, Paper, Stack, Typography } from '@common/components'
import React from 'react'
import image from '@assets/images/expectation/image2.png'
import { RouterLink } from '@lib/router'
import { PRICING_ROUTE } from '@common/constants/routes'
import { useMediaQuery } from '@common/hooks'

const FollowLimitUpgradeModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props
  const isSmDown = useMediaQuery(600)
  if (!open) return null
  return (
    <Modal
      open={open}
      onClose={onClose}
      paperProps={{
        sx: {
          py: 4,
          px: 2,
          maxWidth: '550px',
          width: '95%',
          minHeight: isSmDown ? '200px' : '300px',
          borderRadius: '10px',
        },
      }}
    >
      <Box>
        <Stack sx={{ px: 3, height: '100%' }}>
          <Typography fontWeight={600} variant='h6'>
            Follow Limit Reached
          </Typography>
          <img
            src={image}
            alt='unpaid-feedback'
            width={300}
            style={{ margin: '20px auto', display: isSmDown ? 'none' : 'block' }}
          />

          <Typography variant='body2' color='text.secondary'>
            You've reached your following limit. Upgrade your account to follow more users and
            expand your network.
          </Typography>
          <Stack direction='row' justifyContent='center' spacing={1} mt={2}>
            <Button
              size={isSmDown ? 'small' : 'medium'}
              onClick={onClose}
              color='neutral'
              variant='outlined'
              sx={{ flex: 1 }}
            >

              Maybe later
            </Button>
            <Button
              size={isSmDown ? 'small' : 'medium'}
              variant='contained'
              sx={{ flex: 1 }}
              component={RouterLink}
              to={PRICING_ROUTE}
              onClick={onClose}
            >
              Upgrade
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}

export default FollowLimitUpgradeModal
