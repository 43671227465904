import React, { useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { ProgressBar } from './ProgressBar'
import { Steps } from './Steps'
import { Container } from './ProgressView.styles'

const ProgressView = (props: {
  children: React.ReactNode
  showSteps: boolean
  currentStep: number
  showProgressBar: boolean
  swipeable: boolean
}) => {
  const {
    children,
    showSteps = true,
    currentStep,
    showProgressBar = true,
    swipeable = true,
    ...restProps
  } = props

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false })

  const validateCurrentStep = (step: number) => Math.min(step, React.Children.count(children) - 1)

  // Navigate to the current step when `currentStep` changes
  useEffect(() => {
    if (emblaApi && swipeable) {
      emblaApi.scrollTo(validateCurrentStep(currentStep))
    }
  }, [currentStep, emblaApi, swipeable])

  // Handle swipeable behavior
  const handleSelect = useCallback(() => {
    if (!emblaApi || !swipeable) return
    const selectedIndex = emblaApi.selectedScrollSnap()
    // You can add logic here to update the `currentStep` externally if needed
  }, [emblaApi, swipeable])

  useEffect(() => {
    if (!emblaApi) return
    emblaApi.on('select', handleSelect)
    return () => emblaApi.off('select', handleSelect)
  }, [emblaApi, handleSelect])

  return (
    <Container {...restProps}>
      {showSteps && (
        <Steps
          currentStep={validateCurrentStep(currentStep)}
          numberOfSteps={React.Children.count(children)}
        />
      )}
      <div ref={emblaRef} style={{ overflow: 'hidden', height: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          {React.Children.map(children, (child, index) => (
            <div key={index} style={{ flex: '0 0 100%', minWidth: 0 }}>
              {child}
            </div>
          ))}
        </div>
      </div>
      {showProgressBar && (
        <ProgressBar
          currentStep={validateCurrentStep(currentStep)}
          numberOfSteps={React.Children.count(children)}
          sx={{ position: 'absolute', bottom: 0, left: 0 }}
        />
      )}
    </Container>
  )
}

export default ProgressView
